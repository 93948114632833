export const operatorRoute =  {
  path: "/operator",
  name: "op_admin",
  redirect: '/operator/users',
  component: () => import('../pages/operator/index.vue'),
  meta: {
    requiresAUserType: 'admin'
  },
  children: [
    {
      path: "dashboard",
      name: "op_dashboard",
      component: () => import('../pages/operator/dashboard/index.vue'),
      meta: {
        title: 'Dashboard - ArtemisAds Op',
      }
    },
    {
      path: 'invoice-pdf/:id',
      name: 'operator-invoice-pdf',
      component: () => import('../pages/operator/pdf.vue'),
      meta: {
        title: 'Finance Invoice PDF - ArtemisAds',
      }
    },
    {
      path: "publisher",
      name: "op_Publisher",
      component: () => import('../pages/operator/publisher/index.vue'),
      meta: {
        title: 'Publisher - ArtemisAds Op',
      },
    },
    {
      path: "seller",
      name: "op_Seller",
      component: () => import('../pages/operator/seller/index.vue'),
      meta: {
        title: 'Seller - ArtemisAds Op',
      },
    },
    {
      path: "seller-report",
      name: "op_Seller_Report",
      component: () => import('../pages/operator/seller/sellerReport.vue'),
      meta: {
        title: 'SellerReport - ArtemisAds Op',
      },
    },
    {
      path: "commissions",
      name: "op_Commissions",
      component: () => import('../pages/operator/commissions/index.vue'),
      meta: {
        title: 'Commissions - ArtemisAds Op',
      },
    },
    {
      path: "users",
      name: "op_Users",
      component: () => import('../pages/operator/users/index.vue'),
      meta: {
        title: 'Users - ArtemisAds Op',
      },
    },
    {
      path: "finance/manually-actions",
      name: "op_manuallyActions",
      component: () => import('../pages/operator/finance/manuallyActions/index.vue'),
      meta: {
        title: 'Manually Actions - ArtemisAds Op',
      },
    },
    {
      path: "finance/payments",
      name: "op_Payments",
      component: () => import('../pages/operator/finance/payments/index.vue'),
      meta: {
        title: 'Payments - ArtemisAds Op',
      },
    },
    {
      path: "finance/payments/seller-detail/:id",
      name: "Payments Seller Detail",
      component: () => import('../pages/operator/finance/payments/sellerDetail/index.vue'),
      meta: {
        title: 'Payments Seller Detail - ArtemisAds',
      }
    },
    {
      path: "finance/payments/publisher-detail/:id",
      name: "Payments Publisher Detail",
      component: () => import('../pages/operator/finance/payments/publisherDetail/index.vue'),
      meta: {
        title: 'Payments Publisher Detail - ArtemisAds',
      }
    },
    {
      path: "finance/saas-plan",
      name: "op_SAASPlan",
      component: () => import('../pages/operator/finance/saasPlan/index.vue'),
      meta: {
        title: 'SAAS Plan - ArtemisAds Op',
      },
    },
    {
      path: "finance/transactions",
      name: "op_Transactions",
      component: () => import('../pages/operator/finance/transactions/index.vue'),
      meta: {
        title: 'Transactions - ArtemisAds Op',
      },
    },
  ]
}
